<template>
  <v-menu
    content-class="sl-menu col-visibility-menu"
    :close-on-content-click="false"
    max-height="auto"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="sl-menu-activator"
        data-toolbar-item
        v-on="on"
      >
        <ToolbarButton>
          <div class="sl-menu-activator__wrapper">
            <span class="sl-menu__label">
              {{ selectTitle }}
            </span>
            <icon
              data="@icon/arrow-down.svg"
              class="size-16 color-black menu-button"
            />
          </div>
        </ToolbarButton>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :disabled="item.disabled || disabled"
        class="col-visibility-menu__item"
        @click="getItemHandler(item)"
      >
        <template v-if="item.sublist">
          <v-menu
            content-class="sl-menu col-visibility-menu"
            :close-on-content-click="false"
            :open-delay="subMenuOpenDelay"
            :close-delay="subMenuCloseDelay"
            max-height="auto"
            offset-x
            open-on-hover
          >
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="sl-menu-activator col-visibility-menu__subitem"
                v-on="on"
              >
                <span>
                  {{ getColVisibilityItemTitle(item) }}
                </span>
                <icon
                  data="@icon/arrow-down.svg"
                  class="size-16 color-black rotate--90 arrow"
                />
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(subItem, subItemIndex) in item.sublist"
                :key="subItemIndex"
                :disabled="subItem.disabled || disabled"
                class="col-visibility-menu__item"
                @click="getItemHandler(subItem)"
              >
                <icon
                  v-if="isItemVisible(subItem)"
                  data="@tree/apply_no_padding.svg"
                  class="size-12 visible"
                />
                <v-list-item-title>
                  {{ getColVisibilityItemTitle(subItem) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-else>
          <icon
            v-if="isItemVisible(item)"
            data="@tree/apply_no_padding.svg"
            class="size-12 visible"
          />
          <v-list-item-title>
            {{ getColVisibilityItemTitle(item) }}
          </v-list-item-title>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ToolbarButton from '@/components/Toolbar/ToolbarButton.vue';

export default {
  name: 'ColVisibilityMenu',
  components: {
    ToolbarButton
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    disabled: Boolean
  },
  data() {
    return {
      subMenuOpenDelay: 400,
      subMenuCloseDelay: 400
    };
  },
  computed: {
    selectTitle() {
      return this.title || this.$t('Main.CustomizeColumnsMenu');
    }
  },
  methods: {
    // todo refactor after transition reports to json
    isItemVisible(item) {
      return item.visible ?? item._visible === '1';
    },
    getColVisibilityItemTitle(item) {
      return (item.name || item.fallback) ?? (item.__text || item._name || item?.fallback);
    },
    getItemHandler(item) {
      if (item.sublist) {
        return null;
      }

      return this.handleColVisibilityChange(item);
    },
    handleColVisibilityChange(item) {
      this.$emit('change-col-visibility', {
        class: item.class ?? item._class,
        state: item.visible !== undefined ? !item.visible : !(+item._visible),
        index: item.index !== undefined ? item.index || 0 : item._index || '0'
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/style/components/toolbar/col-visibility-menu.scss';
</style>