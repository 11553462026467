<template>
  <div
    class="page-wrapper"
    :class="{
      'page-wrapper--alerts': isAlertVisible,
      [`page-wrapper--alerts-${numberOfAlerts}`]: isAlertVisible
    }"
  >
    <div class="page-wrapper__alerts">
      <SlAlertLine
        v-if="isExpirationAlertVisible"
        type="error"
        :accent="$t('Web.LicenseDetails.SubscriptionExpired', { 1: expirationLocaleDate })"
        :text="$t('Web.LicenseDetails.ContactAdminToMaintain')"
      />
      <SlAlertLine
        v-if="isOverdated"
        type="warn"
      >
        <template #accent>
          <i18n path="Web.UpdateData.PleaseUpdate">
            <template v-slot:1>
              <SlLink
                :href="{
                  name: routeNames.SETTINGS,
                  hash: `#${settingsTabKeys.PROJECT}`
                }"
                color="yellow"
              >
                {{ $t('Main.Ui.acSettings') }}
              </SlLink>
            </template>
          </i18n>
        </template>
      </SlAlertLine>
      <SlAlertLine
        v-if="frozenDate"
        type="warn"
        :accent="$t('Web.FreezeCalc.AlertLabel', { 1: frozenDate })"
      >
        <template #text>
          <SlLink
            color="yellow"
            @click.prevent="handleUnfreezeSimulation"
          >
            {{ $t('Web.FreezeCalc.Unfreeze') }}
          </SlLink>
        </template>
      </SlAlertLine>
    </div>
    <template v-if="$slots.modal">
      <slot name="modal" />
    </template>
    <template
      v-if="$slots.toolbar && !loading"
      class="page-toolbar"
    >
      <slot name="toolbar" />
    </template>
    <template
      v-if="!loading"
      class="page-content"
    >
      <slot />
    </template>
    <transition name="fade">
      <div
        v-if="loading"
        class="page-loader"
      >
        <slot
          v-if="$slots.loader"
          name="loader"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { settingsTabKeys } from '@/config/shared/settings.config';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'PageWrapper',
  props: {
    loading: Boolean
  },
  data() {
    return {
      routeNames,
      settingsTabKeys,
      alertIgnoredRoutes: [
        routeNames.GET_STARTED,
        routeNames.ACTIVATION,
        routeNames.NO_ACCESS,
        routeNames.SERVER_DOWN
      ]
    };
  },
  computed: {
    ...mapState({
      isOverdated: (state) => state.project.project?.isOverdated,
      frozenDate: (state) => state.project.project?.freeze
    }),
    ...mapGetters({
      isExpired: 'initialization/isExpired',
      expirationLocaleDate: 'initialization/expirationLocaleDate'
    }),
    isExpirationAlertVisible() {
      return this.isExpired && !this.alertIgnoredRoutes.includes(this.$sl_routeName);
    },
    isAlertVisible() {
      return this.isExpirationAlertVisible || this.isOverdated || Boolean(this.frozenDate);
    },
    numberOfAlerts() {
      return +Boolean(this.isExpirationAlertVisible) + +Boolean(this.isOverdated) + +Boolean(this.frozenDate);
    }
  },
  methods: {
    ...mapActions({
      unfreezeProject: 'project/unfreezeProject'
    }),
    handleUnfreezeSimulation() {
      this.unfreezeProject();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/shared/page-wrapper.scss';
</style>
