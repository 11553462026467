<template>
  <div class="sl-search">
    <v-text-field
      ref="input"
      v-model.trim="searchValue"
      :placeholder="$t('Main.SearchStub')"
      type="text"
      class="sl-text-input search tree-search-input"
      solo
      @keydown.enter.prevent="handleEnter"
    >
      <template #append>
        <icon
          v-if="searchValue"
          data="@icon/close.svg"
          class="size-16 search__icon"
          @click="clearSearch"
        />
        <icon
          v-else
          data="@icon/search.svg"
          class="size-16"
        />
      </template>
    </v-text-field>
    <v-menu
      v-if="withResults"
      :value="resultsShown"
      activator=".tree-search-input"
      :content-class="`sl-search__results ${resultsMenuClass}`"
      close-on-content-click
      offset-y
      eager
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in results"
          :key="index"
          @click="handleSelectItem(item)"
        >
          <slot
            v-if="$scopedSlots['result-item']"
            name="result-item"
            v-bind="{ item }"
          />
          <template v-else>
            {{ item.text }}
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { customDebounce, scrollEndListener } from '@/helpers/shared/listeners';

export default {
  name: 'Search',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    results: {
      type: Array,
      required: false,
      default: () => ([])
    },
    // use with resultsLazyLoad
    loadCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    withResults: Boolean,
    resultsLazyLoad: Boolean
  },
  data() {
    return {
      resultsMenuNode: null,
      // used to compare diff result count to stop lazy load (server not send all results count)
      resultsCount: 0,
      loadPortion: 0,
      scrollLoadOffset: 250,
      activeScroll: false
    };
  },
  computed: {
    searchValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);

        if (val.trim()) {
          this.handleUpdateSearch();
        }
      }
    },
    resultsShown() {
      return this.results.length;
    },
    resultsMenuClass() {
      return `${!this.resultsShown ? 'hidden' : ''}`;
    }
  },
  watch: {
    activeScroll(val) {
      this.$emit('check-scroll', val);
    }
  },
  mounted() {
    if (this.withResults && this.resultsLazyLoad) {
      this.resultsMenuNode = document.querySelector('.sl-search__results');
      this.resultsMenuNode.addEventListener('scroll', this.handleLazyLoadResults);

      scrollEndListener(this.resultsMenuNode, () => {
        this.activeScroll = false;
      }, 300);
    }
  },
  beforeDestroy() {
    if (this.resultsMenuNode) {
      this.resultsMenuNode.removeEventListener('scroll', this.handleLazyLoadResults);
    }
  },
  methods: {
    clearSearch() {
      this.searchValue = '';
      this.handleSubmit();
    },
    handleSelectItem(item) {
      this.$emit('select-item', item);
    },
    handleSubmit() {
      this.$emit('submit-search');
    },
    handleEnter() {
      if (this.withResults) {
        return;
      }

      this.handleSubmit();
    },
    handleUpdateSearch: customDebounce(function() {
      if (!this.withResults) {
        return;
      }

      const menuNode = document.querySelector('.sl-search__results');

      menuNode.scrollTop = 0;
      this.resultsCount = 0;
      this.loadPortion = 0;
      this.handleSubmit();
    }, 500),
    handleLazyLoadResults(e) {
      if (this.resultsCount === this.results.length) {
        return e.preventDefault();
      }

      if (!this.activeScroll) {
        this.activeScroll = true;
      }

      const scrollHeight = e.target.scrollHeight;
      const alreadyScrolled = e.target.scrollTop + e.target.offsetHeight;

      if (scrollHeight - alreadyScrolled < this.scrollLoadOffset) {
        this.loadPortion += 1;
        this.resultsCount = this.results.length;
        this.loadCallback(this.loadPortion);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/toolbar/search.scss';
</style>
