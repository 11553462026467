<template>
  <div
    class="sl-context-menu"
    @click.right.prevent.stop
  >
    <ul
      :id="elementId"
      v-click-outside="vcoConfig"
      class="sl-context-menu__list"
      :class="{
        'sl-context-menu--active': isActive
      }"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="sl-context-menu__item-wrapper"
        :class="optionClasses(option)"
      >
        <template v-if="$scopedSlots[option.key]">
          <slot
            :name="option.key"
            v-bind="{
              ...option,
              handler: optionHandler(option)
            }"
          />
        </template>
        <OptionItem
          v-else
          :handler="optionHandler(option)"
        >
          {{ option.name }}
        </OptionItem>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import OptionItem from '@/components/Shared/SlContextMenu/OptionItem';

export default {
  name: 'SlContextMenu',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { OptionItem },
  props: {
    elementId: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    offClickOutside: Boolean
  },
  data() {
    return {
      item: null,
      scrollNode: null,
      escKeyCode: 27,
      isActive: false
    };
  },
  computed: {
    vcoConfig() {
      return {
        handler: this.hideContextMenu,
        middleware: this.clickOutsideMiddleware,
        events: ['mousedown'],
        isActive: !this.offClickOutside
      };
    }
  },
  mounted() {
    document.body.addEventListener('keyup', this.onEscKeyRelease);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onEscKeyRelease);
  },
  methods: {
    showMenu(event, item, _, __, scrollContainerSelector) {
      this.scrollNode = document.querySelector(scrollContainerSelector);
      this.item = item;

      const menuNode = document.getElementById(this.elementId);

      if (!menuNode) {
        return;
      }

      this.scrollNode?.classList.add('context-menu-overlay');
      this.isActive = true;

      this.$nextTick(() => {
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = menuNode;
        let { x: left, y: top} = event;

        if (left + menuWidth > window.innerWidth) {
          left = left - menuWidth;
        }

        if (top + menuHeight > window.innerHeight) {
          top = top - menuHeight > 0 ? top - menuHeight : 10;
        }

        menuNode.style.left = left + 'px';
        menuNode.style.top = top + 'px';
      });

      if (this.scrollNode) {
        this.scrollNode.addEventListener('scroll', this.onScroll, { once: true });
      }
    },
    onScroll() {
      if (this.clickOutsideMiddleware()) {
        this.hideContextMenu();
      }
    },
    hideContextMenu() {
      const menuNode = document.getElementById(this.elementId);

      if (menuNode) {
        menuNode.removeAttribute('style');
        this.isActive = false;
        this.scrollNode?.classList.remove('context-menu-overlay');
        this.$emit('menu-closed');

        if (this.scrollNode) {
          this.scrollNode.removeEventListener('scroll', this.onScroll);
          this.scrollNode = null;
        }
      }
    },
    clickOutsideMiddleware() {
      const menuNode = document.getElementById(this.elementId);

      return menuNode?.classList.contains('sl-context-menu--active');
    },
    optionClasses(option) {
      return [
        option.class,
        option.type === 'divider'
          ? 'sl-context-menu__divider'
          : ''
      ];
    },
    optionHandler(option) {
      if (option.subList) {
        return () => {};
      }

      return () => this.optionClicked(option);
    },
    optionClicked(option) {
      this.hideContextMenu();

      this.$emit('option-clicked', {
        item: this.item,
        option
      });
    },
    onEscKeyRelease(event) {
      if (event.keyCode === this.escKeyCode && this.isActive) {
        this.hideContextMenu();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/shared/sl-context-menu/index.scss';
</style>
