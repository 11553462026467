<template>
  <div
    class="toolbar-skeleton"
    :class="{
      'toolbar-skeleton--grey': color === 'grey',
      'toolbar-skeleton--grey-darker': color === 'grey-darker'
    }"
  />
</template>

<script>
export default {
  name: 'ToolbarSkeleton',
  props: {
    color: {
      type: String,
      default: 'grey'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/skeleton/toolbar-skeleton.scss';
</style>