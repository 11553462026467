<template>
  <div
    :class="{
      'toolbar-wrapper--extended': $slots.filters
    }"
  >
    <div
      v-if="$slots.filters"
      class="toolbar-wrapper toolbar-wrapper__filters"
    >
      <slot name="filters" />
    </div>
    <div class="toolbar-wrapper toolbar-wrapper__bottom">
      <div class="toolbar-wrapper__content">
        <div
          v-if="$slots.search"
          class="toolbar-wrapper__search"
        >
          <slot name="search" />
        </div>
        <div class="toolbar-wrapper__main">
          <slot />
        </div>
      </div>
      <div
        v-if="$scopedSlots.additional"
        class="toolbar-wrapper__additional"
      >
        <slot name="additional" />
      </div>
      <v-menu
        ref="toolbarBurgerMenu"
        v-model="menuShown"
        content-class="sl-menu toolbar-burger__menu"
        :close-on-content-click="false"
        :close-on-click="false"
        left
        offset-y
        eager
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="sl-menu-activator toolbar-burger__activator"
            v-on="on"
          >
            <ToolbarButton
              v-if="isBurgerVisible"
              class="toolbar-burger"
              :disabled="!isBurgerVisible"
            >
              <icon
                data="@icon/three-dots.svg"
                class="size-16"
              />
            </ToolbarButton>
          </div>
        </template>
        <div
          v-click-outside="vcoConfig"
          class="toolbar-burger__menu-content"
          @click="handleMenuContentClick"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import ToolbarButton from './ToolbarButton.vue';
import { customThrottle } from '@/helpers/shared/listeners';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ToolbarWrapper',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    ToolbarButton
  },
  data() {
    return {
      menuShown: false,
      toolbarNode: null,
      toolbarContentNode: null,
      toolbarMainNode: null,
      toolbarMenuContentNode: null,
      toolbarItemNodeList: null,
      overflowSize: 0,
      emptySize: 0,
      minEmptySizeGap: 50,
      checkOverflowMinWidth: 600,
      originalItemSizes: [],
      sidebarToggleDuration: 500
    };
  },
  computed: {
    ...mapState({
      isSidebarExpanded: (state) => state.sidebar
    }),
    ...mapGetters({
      someModalOpen: 'modals/someModalOpen'
    }),
    isOverflowed() {
      return this.overflowSize > 0;
    },
    isToolbarMenuEmpty() {
      if (!this.toolbarMenuContentNode) {
        return true;
      }

      return !this.toolbarMenuContentNode.children.length;
    },
    isBurgerVisible() {
      return !this.isToolbarMenuEmpty;
    },
    firstMenuItemWidth() {
      if (!this.originalItemSizes.length) {
        return null;
      }

      return this.originalItemSizes[0];
    },
    vcoConfig() {
      return {
        handler: this.handleClickMenuOutside,
        middle: this.clickOutsideMiddleware,
        isActive: this.menuShown
      };
    }
  },
  watch: {
    isSidebarExpanded() {
      const start = Date.now();

      const interval = setInterval(() => {
        const end = Date.now();

        if (end - start >= this.sidebarToggleDuration) {
          return clearInterval(interval);
        }

        this.checkOverflow();
      }, 50);
    }
  },
  mounted() {
    this.setNodes();

    this.$nextTick(this.checkOverflow);

    window.addEventListener('resize', this.throttleCheckOverflow);
  },
  updated() {
    this.$nextTick(this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttleCheckOverflow);
  },
  methods: {
    setNodes() {
      this.toolbarNode = document.querySelector('.toolbar-wrapper__bottom');
      this.toolbarContentNode = document.querySelector('.toolbar-wrapper__content');
      this.toolbarMainNode = document.querySelector('.toolbar-wrapper__main');
      this.toolbarMenuContentNode = document.querySelector('.toolbar-burger__menu-content');
    },
    resetNodes() {
      this.toolbarNode = null;
      this.toolbarContentNode = null;
      this.toolbarMainNode = null;
      this.toolbarMenuContentNode = null;

      this.$nextTick(this.setNodes);
    },
    handleMenuContentClick() {
      if (this.someModalOpen) {
        this.menuShown = false;
      }
    },
    handleClickMenuOutside() {
      const activeMenus = document.querySelectorAll('.menuable__content__active');

      if (activeMenus.length <= 1) {
        this.menuShown = false;
      }
    },
    clickOutsideMiddleware() {
      return this.$refs.toolbarBurgerMenu.classList.contains('menuable__content__active');
    },
    checkOverflow(e) {
      if (e && this.menuShown) {
        this.menuShown = false;
      }

      if (window.innerWidth <= this.checkOverflowMinWidth || !this.toolbarNode) {
        return;
      }

      this.overflowSize = this.toolbarNode.scrollWidth - this.toolbarNode.offsetWidth;
      this.emptySize = this.toolbarNode.scrollWidth - this.toolbarContentNode.offsetWidth;

      this.appendToMenu();
      this.$nextTick(this.appendToToolbar);
    },
    appendToMenu() {
      if (!this.isOverflowed) {
        return;
      }

      this.toolbarItemNodeList = this.toolbarMainNode.querySelectorAll('[data-toolbar-item]');

      if (!this.toolbarItemNodeList.length) {
        return;
      }

      let overflowAcc = 0;

      for (let i = this.toolbarItemNodeList.length - 1; i >= 0; i--) {
        if (this.overflowSize > overflowAcc) {
          this.originalItemSizes.unshift(this.toolbarItemNodeList[i].offsetWidth);
          this.toolbarMenuContentNode.prepend(this.toolbarItemNodeList[i]);

          overflowAcc += this.toolbarItemNodeList[i].offsetWidth;
        } else {
          break;
        }
      }

      this.resetNodes();
    },
    appendToToolbar() {
      if (this.isToolbarMenuEmpty) {
        return;
      }

      const menuItems = this.toolbarMenuContentNode.querySelectorAll('[data-toolbar-item]');
      let emptyAcc = this.firstMenuItemWidth + this.minEmptySizeGap;

      for (let i = 0; i <= menuItems.length - 1; i++) {
        if (emptyAcc <= this.emptySize) {
          this.toolbarMainNode.append(menuItems[i]);
          this.originalItemSizes.shift();

          emptyAcc += menuItems[i].offsetWidth;
        } else {
          break;
        }
      }

      this.resetNodes();
    },
    throttleCheckOverflow: customThrottle(function(e) {
      return this.checkOverflow(e);
    }, 10)
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/toolbar/toolbar-wrapper.scss";
</style>