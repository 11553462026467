<template>
  <SlModal
    :id="id"
    :title="title"
    persistent
    @show="onShow"
    @hide="onHide"
  >
    <div class="note-modal modal-content">
      <div
        v-if="noteInfo"
        class="note-modal__info body-2 grey-80"
      >
        {{ noteInfo }}
      </div>
      <SlTextArea
        v-model="newNote"
        :placeholder="$t('EditNote.Ui.memo:placeholderText')"
        :maxlength="9999"
        height="96"
        bottom-round
        autofocus
      />
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="notChanged"
          @click="handleConfirm"
        >
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { formatFullTime, utcTimezoneOffsetTime } from '@/helpers/date/date';
import regExp from '@/helpers/utils/regExp';
import { noteTypes } from '@/config/shared/note.config';

export default {
  name: 'NoteModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newNote: ''
    };
  },
  computed: {
    ...mapState({
      body: state => state.note.currentNote?.body,
      author: state => state.note.currentNote?.author,
      editTime: state => state.note.currentNote?.editTime,
      noteType: state => state.note.noteType
    }),
    title() {
      if (this.author) {
        return this.$t('Main.EditNote');
      }

      return this.$t('Main.Ui.acToolbarNote');
    },
    noteInfo() {
      if (!this.editTime && !this.author) {
        return null;
      }

      return this.$t('EditNote.EditedBy', {
        1: formatFullTime(this.editTime),
        2: `UTC ${utcTimezoneOffsetTime}`,
        3: this.author
      });
    },
    saveCallback() {
      if (this.noteType === noteTypes.DEMAND_CELL) {
        return this.updateCellNote;
      }

      return this.updateNote;
    },
    noteWithoutSpaces() {
      return this.newNote.replace(regExp.spacesOrNewlines, '');
    },
    notChanged() {
      return this.noteWithoutSpaces === this.body || (!this.body && !this.noteWithoutSpaces);
    }
  },
  methods: {
    ...mapActions({
      updateNote: 'note/updateNote',
      updateCellNote: 'note/updateCellNote'
    }),
    onShow() {
      if (!this.body) {
        return;
      }

      this.newNote = this.body;
    },
    onHide() {
      this.newNote = '';
    },
    handleConfirm() {
      this.saveCallback(this.newNote);

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils/color";
@import "@/style/utils/mixins";
@import "@/style/utils/variable";

.note-modal {
  &.modal-content {
    min-height: 100px;
    padding-top: 0;
  }

  &__info {
    margin: 8px 0 12px;
    padding: 6px 12px;

    background: $grey-10;
    border-radius: $border-radius-xs;
  }
}
</style>
