<template>
  <div
    class="table-input-wrapper"
    :class="{
      'table-input-wrapper--disabled': disabled || offEditableBg,
      'table-input-wrapper--number': !isText || isFormula,
      'table-input-wrapper--invalid': invalid,
      [`color--${foreground}`]: foreground
    }"
    tabindex="-1"
    @focus="onInputFocus"
  >
    <input
      v-if="isInputVisible"
      ref="inputRef"
      v-model.trim="vModel"
      type="text"
      class="table-input"
      :maxLength="maxLength"
      :disabled="disabled"
      @focus="onInputFocus"
      @keydown.enter.stop="$refs.inputRef.blur()"
      @blur.stop="onSubmit"
    >
    <div
      v-else
      class="table-input-text"
    >
      {{ inputTextValue }}
    </div>
  </div>
</template>

<script>
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'TableInput',
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: ''
    },
    // text will show with focusVisible prop
    textValue: {
      type: [Number, String],
      required: false,
      default: ''
    },
    // used in handlers only
    type: {
      type: String,
      required: false,
      default: 'number',
      validator: (v) => v && ['text', 'number', 'formula'].includes(v)
    },
    maxLength: {
      type: Number,
      required: false,
      default: 10
    },
    foreground: {
      type: String,
      required: false,
      default: ''
    },
    inputRegexp: {
      type: RegExp,
      required: false,
      default: () => regExp.intInput
    },
    submitRegexp: {
      type: RegExp,
      required: false,
      default: () => regExp.intSubmit
    },
    disabled: Boolean,
    focusVisible: Boolean,
    offEditableBg: Boolean,
    invalid: Boolean
  },
  data() {
    return {
      isChanged: false,
      isFocused: false
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        if (!this.isChanged) {
          this.isChanged = true;
        }

        if (this.shouldSkipChecks) {
          return this.$emit('input', val);
        }

        if (val && !this.inputRegexp.test(val)) {
          this.$refs.inputRef.value = this.value;

          return;
        }

        this.$emit('input', val);
      }
    },
    isInputVisible() {
      return !this.focusVisible || this.isFocused;
    },
    isValid() {
      if (this.shouldSkipChecks) {
        return this.isChanged;
      }

      return this.isChanged && this.submitRegexp.test(this.vModel);
    },
    isText() {
      return this.type === 'text';
    },
    isFormula() {
      return this.type === 'formula';
    },
    shouldSkipChecks() {
      return this.isText || this.isFormula;
    },
    inputTextValue() {
      if (this.shouldSkipChecks) {
        return this.textValue || this.vModel;
      }

      return this.textValue || this.vModel;
    }
  },
  methods: {
    onInputFocus() {
      if (this.disabled) {
        return;
      }

      this.isFocused = true;

      this.$nextTick(() => this.$refs.inputRef.select());
    },
    onSubmit() {
      this.isFocused = false;

      if (!this.isValid) {
        return;
      }

      this.$emit('submit', this.$refs.inputRef.value.trim());
      this.isChanged = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/table-input.scss";
</style>
