<template>
  <div
    class="sl-context-menu__item"
    :class="{
      'sl-context-menu__item--disabled': disabled,
      'with-shortcut': $scopedSlots.shortcut
    }"
    @click.stop="optionHandler"
  >
    <slot />
    <div
      v-if="$scopedSlots.shortcut"
      class="shortcut-block"
    >
      <slot name="shortcut" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionItem',
  props: {
    handler: {
      type: Function,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    optionHandler() {
      if (this.disabled) {
        return null;
      }

      return this.handler;
    }
  }
};
</script>
