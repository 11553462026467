<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    @on-enter="handleCreate"
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <SlAlert
        v-if="additionTitle"
        :text="additionTitle"
        type="info"
      />
      <ValidationObserver
        ref="codeSelectObserver"
      >
        <SlValidate
          v-slot="{ invalid }"
          vid="search"
          :rules="`required|sl_unique_item_code:${activeItemCode}`"
        >
          <SlCombobox
            v-model="newVersion"
            :options="itemCodes"
            :select-label="$t('Web.Modals.ReplaceWith.Item')"
            :placeholder="$t('Web.Select.SubstituteModalSelect')"
            :is-invalid="invalid"
            :internal-search="false"
            :loading="isLoading"
            @search="handleSearch"
          >
            <template #icon>
              <SlInfoButton v-tooltip.bottom="getTooltip($t('Web.Modals.ReplaceWith.InfoTooltip'))" />
            </template>
          </SlCombobox>
        </SlValidate>
      </ValidationObserver>
    </div>
    <template #footer>
      <SlModalFooter>
        <SlButton
          class="sl-button"
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          class="sl-button"
          @click="handleCreate"
        >
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { mapGetters, mapActions } from 'vuex';
import { customDebounce } from '@/helpers/shared/listeners';
import { getTooltip } from '@/helpers/shared/tooltip';
import modalsId from '@/config/shared/modalsId.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';

export default {
  name: 'SubstituteItem',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      slErrorCodes,
      activeItemCode: null,
      newVersion: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      itemClones: 'substitutions/itemClone',
      itemCodes: 'project/itemCodes'
    }),
    modalTitle() {
      return this.$t('Web.Modals.ReplaceWith.Title', {
        1: this.activeItemCode
      });
    },
    additionTitle() {
      if (!this.itemClones) {
        return '';
      }

      const count = +this.itemClones?._count;
      const name = this.itemClones?._name;

      switch (count) {
        case 0:
          return '';
        case 1:
          return this.$t('Ban.One', {
            1: name
          });
        default:
          return this.$t('Ban.Many', {
            1: name,
            2: count - 1
          });
      }
    }
  },
  methods: {
    ...mapActions({
      replaceItem: 'substitutions/replaceItem',
      fetchItemCodes: 'project/fetchItemCodes'
    }),
    getTooltip,
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key]) {
          this[key] = props[key];
        }
      });
    },
    onModalHide() {
      this.newVersion = '';
      this.activeItemCode = '';
      this.isLoading = false;
    },
    async handleCreate() {
      const isValid = await this.$refs.codeSelectObserver.validate();

      if (isValid) {
        try {
          await this.replaceItem({
            item: this.newVersion,
            uniId: this.activeItemCode
          });
        } catch (e) {
          if (e?.code === this.slErrorCodes.IMPLICIT_USE_INACCESSIBLE_RESOURCE) {
            this.showModal(modalsId.CONFIRM, {
              title: this.$t('ReplaceWith.Title'),
              text: this.$t('ReplaceWith.WantToMerge', {1: this.activeItemCode, 2: this.newVersion, 3: this.activeItemCode}),
              yesHandler: this.replaceItem.bind(this, {
                item: this.newVersion,
                uniId: this.activeItemCode,
                allowImplicitMerge: true
              })
            });
          }
        }

        this.hideModal(this.id);
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    searchItemCodes: customDebounce(async function(query) {
      await this.fetchItemCodes({ query });

      this.isLoading = false;
    }, 200),
    handleSearch(query) {
      if (!query) {
        return;
      }

      this.isLoading = true;

      this.searchItemCodes(query);
    }
  }
};
</script>
<style lang="scss" scoped>
.sl-alert {
  margin-bottom: 16px;
}
</style>
