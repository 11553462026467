<template>
  <button
    class="toolbar-button"
    :disabled="disabled"
  >
    <slot />
    <div
      v-if="label"
      class="toolbar-button__label"
    >
      {{ label }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'ToolbarButton',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    disabled: Boolean
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/toolbar/toolbar-button.scss";
</style>
